<template>
  <div class="skeleton-wrapper">
    <div v-for="n in count" :key="n">
      <div class="head">
        <div class="skeleton-item"></div>
        <div class="skeleton-item"></div>
      </div>

      <div class="content">
        <div>
          <div class="skeleton-item"></div>
          <div class="skeleton-item"></div>
        </div>
        <div>
          <div class="skeleton-item"></div>
          <div class="skeleton-item"></div>
          <div class="skeleton-item"></div>
        </div>
      </div>
      <div class="skeleton-item last"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FixturesSkeletonLoader",
  props: {
    count: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style scoped>
.skeleton-wrapper {
  display: flex;
  flex-direction: column;
  & > div {
    border-bottom: 1px solid var(--fixture-border);
    padding: 8px 0px;
    padding-bottom: 0;
  }
}

.skeleton-item {
  width: 100%;
  height: 20px;
  background-color: var(--skeleton);
  margin-bottom: 10px;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    width: 22%;
    height: 13px;
  }
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.content > div:nth-child(1) {
  width: 35%;
  display: flex;
  flex-direction: column;
  div {
    margin-top: 1px;
    height: 13px;
  }
}

.content > div:nth-child(2) {
  width: 60%;
  height: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    margin-left: 4px;
    height: 40px;
  }
}

.last {
  width: 100px;
  margin-left: auto;
  margin-right: 0;
  height: 13px;
}
</style>
