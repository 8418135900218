<template>
  <div class="wrap">
    <Banner />
    <div class="scrollable-wrapper">
      <Sports :live="true"></Sports>
    </div>

    <div class="load page_container" v-if="loading">
      <FixturesSkeletonLoader :count="10" />
    </div>

    <div
      v-for="sport in liveSports"
      :key="sport.sport_id"
      v-show="sport.games < 1"
      class="no-games-message"
    >
      {{ $t("checkBackSoon") }}<br />
      <a @click="setSportMenu(1, 'Soccer')" class="btn btn-warning">
        {{ $t("playMoreGames") }}
      </a>
    </div>

    <div v-if="livedata.length < 1">
      <NoData
        :title="$t('noLiveGameForSport')"
        :description="$t('kindlyCheckBackLater')"
      />
    </div>

    <div
      v-for="game in livedata"
      :key="game.match_id"
      style=""
      class="game-card"
    >
      <div class="game-type">
        <div class="country">
          {{ game.country }} • {{ shortenName(game.tournament, 40) }}
        </div>

        <div class="date">
          <div>
            <span>
              <img
                class="blinking-image"
                style="width: 15px"
                src="/img/live-video.png"
              />
              {{ game.event_time }} /
              <span style="color: var(--red)"> {{ game.match_status }} </span>
            </span>
          </div>
        </div>
      </div>

      <div class="games">
        <div class="px-0 game-card-teams">
          <router-link
            :to="{
              name: 'match',
              params: {
                game_id: game.game_id === undefined ? 0 : game.game_id,
                name: toURL(game.name),
                status: live ? 'live' : 'prematch',
              },
            }"
            class="match d-block font-weight-bold"
            style="text-decoration: none"
          >
            <div>
              <span class="score" v-text="game.home_score"></span>
              {{ shortenName(game.home_team, 20) }}
            </div>
            <div class="mb-0">
              <span class="score" v-text="game.away_score"></span>
              {{ shortenName(game.away_team, 20) }}
            </div>
          </router-link>
        </div>

        <div class="odds">
          <Odd
            v-for="outcome in game.highlight_market.outcomes"
            :key="outcome.outcome_id"
            v-bind:class="getTeamClass(outcome.alias)"
            style="
              margin: 0px !important;
              padding: 2px !important;
              height: fit-content;
            "
            v-bind:date="game.date"
            v-bind:match_id="game.match_id"
            v-bind:market_name="game.highlight_market.market_name"
            v-bind:market_id="game.highlight_market.market_id"
            v-bind:status_name="game.highlight_market.status_name"
            v-bind:market_status="game.highlight_market.status"
            v-bind:specifier="game.highlight_market.specifier"
            v-bind:home_team="game.home_team"
            v-bind:away_team="game.away_team"
            v-bind:pick="outcome"
            v-bind:producer_id="game.producer_id"
            v-bind:producer_status="game.producer_status"
            v-bind:status="game.status"
            v-bind:sport_id="game.sport_id"
            v-bind:outcome_id="outcome.outcome_id"
            v-bind:odds="outcome.odds"
            v-bind:alias="outcome.alias"
            v-bind:active="outcome.active"
            v-bind:probability="outcome.probability"
            v-bind:previous_odds="outcome.previous_odds"
          />
        </div>
      </div>

      <div class="markets">
        <p>+{{ game.active_markets }} {{ $t("markets") }}</p>
      </div>
    </div>
    <div class="space-1"></div>
  </div>
</template>

<script>
import fix from "@/services/fix.js";
import Odd from "../../components/Odd.vue";
import NoData from "../../components/ui/NoData.vue";
import FixturesSkeletonLoader from "../../components/ui/FixturesSkeletonLoader.vue";
import mqtt from "mqtt";
import Banner from "../../components/banner/Banner.vue";
const Sports = () => import("../../components/accordion/sports.vue");

export default {
  name: "Live",
  components: {
    FixturesSkeletonLoader,
    Sports,
    Odd,
    NoData,
    Banner,
  },
  data() {
    return {
      livedata: [],
      loading: true,
      error: null,
      selectedTab: "popular",
      hours: 0,
      showDatepicker: false,
      selectedSportName: "All",
      activeTab: "football",
      sub_page: "",
      total_game_count: 0,
      liveSports: [],
      page: 1,
      mqttClient: false,
    };
  },
  mounted() {
    this.$store.dispatch("setSportID", 1);
    this.fetchHighlights("live");
    var vm = this;
    this.$store.dispatch("setCurrentPage", "live");
    this.$store.dispatch("setHour", -1);
    this.$store.dispatch("setCompetitionID", 0);
    this.EventBus.$on("total-games", function (count) {
      vm.total_game_count = count;
    });

    this.initMqtt();
  },
  methods: {
    initMqtt: function () {
      let marketID = 1; // allowed values "1", "186", "10", "29", "11", "26", "18"
      let specifier = "";
      let gameStatus = "live"; // either prematch or live should be updated based on the page the use is in

      // on the home page where we load all the games from highlights endpoint, we want to get updates for all the games
      // but for only the selected market ID
      // in this scenario we subscribe to wilcard topic at the match level represented by +
      // further reference came be made at https://www.emqx.com/en/blog/advanced-features-of-mqtt-topics
      let matchID = "+"; // use + to subscribe to all matches, otherwise use a particular matchID

      let topics = [
        `feeds/${gameStatus}/market_status/${matchID}/${marketID}/${specifier}`, // subscribe to all ${gameStatus} games for marketID ${marketID} , this is only applicable to markets configured on highlights i.e "1", "186", "10", "29", "11", "26", "18"
        "feeds/producer_status", // subscribe to all producer status updates
      ];

      var endpoint = process.env.VUE_APP_URL_MQTT_HOST;
      var vm = this;
      if (this.mqttClient !== false) {
        this.mqttClient.end();
      }

      const options = {
        clean: true, // retain session
        connectTimeout: 4000, // Timeout period
        // Authentication information
        clientId: this.getClientID(),
        username: process.env.VUE_APP_URL_MQTT_USER,
        password: process.env.VUE_APP_URL_MQTT_PASS,
      };

      var client = mqtt.connect(endpoint, options);

      client.on("connect", function () {
        // subscribe to home page topics to update matches
        vm.jQuery.each(topics, function (k, v) {
          client.subscribe(v, function (err) {
            if (!err) {
              // console.log("subscribed to | " + v);
            }
          });
        });
      });

      client.on("message", function (topic, msg) {
        var payload = JSON.parse(msg.toString()); // converts binary message to text then parses it as JSON
        vm.uxUpdate(payload);
      });

      this.mqttClient = client;

      this.$store.dispatch("setWebSocketState", client);
    },

    setActive(tab) {
      this.activeTab = tab;
    },
    setSportMenu: function (sport_id, sport_name) {
      this.sport_name = sport_name;
      this.$store.dispatch("setSportID", sport_id);
      this.$store.dispatch("setCurrentPage", sport_name);
      this.$router.push({ name: "sport", params: {} });
    },
    setSubPage: function (page) {
      this.$store.dispatch("setCurrentSubPage", page);
      this.sub_page = page;
    },
    geSubPageActiveClass: function (subpage) {
      return subpage === this.sub_page ? "active" : "";
    },
    setSport1: function (sportID) {
      sportID = parseInt(sportID);
      this.$store.dispatch("setSportID", sportID);
    },

    sports: function () {
      var vm = this;
      var path = process.env.VUE_APP_BASE_FIXTURE_URL + "/live/counter";
      fix
        .get(path)
        .then((res) => {
          const hasSportId1 =
            res.data.sports &&
            res.data.sports.some((sport) => sport.sport_id === 1);
          if (hasSportId1) {
            vm.liveSports = res.data.sports;
          } else {
            const firstSportId = res.data.sports[0].sport_id;
            console.log("Live Sport-->" + firstSportId);
            vm.$store.dispatch("setSportID", firstSportId);
            vm.liveSports = res.data.sports;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    openLinkInNewTab(url) {
      window.open(url, "_blank");
    },

    updateSport({ sportId, sportName }) {
      this.sport_id = sportId;
      this.selectedSportName = sportName;
      this.fetchHighlights("live");
    },

    toURL: function (name) {
      if (name === undefined) {
        return "-";
      }

      return name.replace(/[^a-z0-9+]+/gi, "-");
    },

    async fetchHighlights(filter) {
      this.loading = true;
      this.error = null;

      let params = {
        page: this.page,
        per_page: 20,
      };
      if (filter === "live") {
        params.match_live_status = 1;
      }
      var url =
        process.env.VUE_APP_BASE_FIXTURE_URL + "/highlights/" + this.sport_id;
      try {
        const response = await fix.get(url, { params });

        if (
          !response.data ||
          !response.data.data ||
          response.data.data.length === 0
        ) {
          this.livedata = [];
        } else {
          this.livedata = response.data.data;
        }
      } catch (error) {
        this.error = "Failed to fetch data";
        console.error("Fetch error:", error);
        this.loading = false;
      } finally {
        this.loading = false;
      }
      this.loading = false;
    },

    getTeamClass(alias) {
      if (alias === "1") return "home-team";
      if (alias === "X") return "draw-team";
      if (alias === "2") return "away-team";
      return "";
    },
  },

  computed: {
    betslip_count: function () {
      return this.$store.state.betslip.total;
    },
    current_page: function () {
      return this.$store.state.current_page;
    },
    sport_id: function () {
      return this.$store.state.sport_id;
    },
  },
  watch: {
    fixtureFilter(newValue, oldValue) {
      this.page = 1;
      this.firstTimeLoading = true;
      this.error = false;
      this.clearData();
      if (newValue !== oldValue) {
        this.initiateCall();
      }
    },
    market_filters(newValue, oldValue) {
      this.page = 1;
      this.firstTimeLoading = true;
      this.error = false;
      this.clearData();
      if (newValue !== oldValue) {
        this.initiateCall();
      }
    },
    sport_id: function (newValue, oldValue) {
      this.page = 1;
      // this.firstTimeLoading = true;
      // this.error = false;
      this.livedata = [];
      if (parseInt(oldValue) > 0 && parseInt(newValue) !== parseInt(oldValue)) {
        this.fetchHighlights("live");
        // this.initMqtt();
      }
    },
  },

  beforeDestroy: function () {
    // console.log("beforeDestroy");
    if (this.mqttClient !== false) {
      this.mqttClient.end();
      this.mqttClient = false;
    }
  },
  destroyed: function () {
    // console.log("destroyed");
    if (this.mqttClient !== false) {
      this.mqttClient.end();
      this.mqttClient = false;
    }
  },
};
</script>

<style scoped src="./index.css"></style>
